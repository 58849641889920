<template>
  <div>
    <ul class="biz-breadcrumb">
      <li><router-link to="/supplier/manage">{{$t('SYSTEM_MANAGEMENT.TITLE')}}</router-link></li>
      <li><a>{{$t('PROFILE.TITLE')}}</a></li>
    </ul>
    <div class="page">
      <div class="page-title">
        <h1>{{$t('PROFILE.TITLE')}}</h1>
        <p>{{$t('PROFILE.DESCRIPTION')}}</p>
      </div>
      <div class="card biz-primary">
        <div class="card-header">
          {{$t('PROFILE.HEADER')}}
          <div class="control">
          </div>
        </div>
        <div class="card-body">
          <ValidationObserver v-slot="{ handleSubmit }" ref="form">
            <form @submit.prevent="handleSubmit(onSave)">
              <div class="row">
                <div class="col-3 border-right ">
                  <div class="sub-title underlined mb-4">
                    <h2 class="font-weight-bold mb-4">
                      {{$t('PROFILE.ACCOUNT_INFO')}}
                    </h2>
                    <div class="control">
                       <button v-if="!editMode" class="btn btn-primary btn-sm no-min mb-4" type="button"  @click.prevent="editMode = true;">{{$t('COMMON.EDIT')}}</button>
                    </div>
                  </div>
                  <p class="">
                    <label class="font-weight-bold">{{$t('HEADER.COMPANY_CODE')}}</label><br/>
                    {{$store.state.user.companyDisplayCode}}
                  </p>
                  <p class="">
                    <label class="font-weight-bold">{{$t('COMPANY_INFO.COMPANY_NAME')}}</label><br/>
                    {{companyName}}
                  </p>
                  <p class="">
                    <label class="font-weight-bold">{{$t('COMMON.NAME')}}</label><br/>
                    <span v-if="editMode">
                      <validation-provider :name="$t('COMMON.NAME')" rules="required" v-slot="{ errors }">
                        <b-form-input name="full_name" v-model="user.name"/>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </validation-provider>
                    </span>
                    <span v-else>
                      {{ user.name || $t('COMMON.NOT_REGISTERED') }}
                    </span>
                  </p>
                  <p>
                    <label class="font-weight-bold">{{$t('USERS.ORGANIZATION_NAME')}}</label><br/>
                    <template v-if="editMode">
                      <validation-provider :name="$t('USERS.ORGANIZATION_NAME')" rules="required" v-slot="{ errors }">
                        <b-form-input name="organization_name" v-model="user.organizationName"/>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </validation-provider>
                    </template>
                    <template v-else>
                      {{ user.organizationName || $t('COMMON.NOT_REGISTERED') }}
                    </template>
                  </p>
                  <p>
                    <label class="font-weight-bold">{{$t('COMMON.EMAIL_ADDRESS')}}</label><br/>
                    <template v-if="editMode">
                      <validation-provider :name="$t('COMMON.EMAIL_ADDRESS')" rules="required|email" v-slot="{ errors }">
                        <b-form-input name="email" v-model="user.email"/>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </validation-provider>
                    </template>
                    <template v-else>
                      {{ user.email || $t('COMMON.NOT_REGISTERED') }}
                    </template>
                  </p>
                  <p>
                    <label class="font-weight-bold">{{$t('COMMON.PHONENUMBER')}}</label><br/>
                    <template v-if="editMode">
                      <validation-provider :name="$t('COMMON.PHONENUMBER')" :rules="{required: true, phonenumber:true  }" v-slot="{ errors }">
                        <b-form-input name="mobile_phone" v-model="user.companyMobilePhone"/>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </validation-provider>
                      
                    </template>
                    <template v-else>
                      {{ user.companyMobilePhone || $t('COMMON.NOT_REGISTERED') }}
                    </template>
                  </p>
                  <p>
                    <label class="font-weight-bold">{{$t('USERS.ROLES')}}</label><br/>
                    <template v-if="editMode">
                      <b-form-input id="roles_input" disabled :value="user.roles.map(r => r.roleName).join(', ')" title="※担当者情報画面から変更してください。"/>
                    </template>
                    <template v-else>
                      <pre class="override-font">{{ user.roles.map(r => r.roleName).join('\n') || $t('COMMON.NOT_REGISTERED') }}</pre>
                    </template>
                  </p>                  
                  <p>
                    <label class="font-weight-bold">言語設定 / Language Preference / 语言首选项</label><br/>
                    <template v-if="editMode">
                      <b-form-select class="w-100" v-model="user.languageCode">
                        <option value="JAPANESE">[JA] JAPANESE</option>
                        <option value="ENGLISH">[EN] ENGLISH</option>
                        <option value="CHINESE (SIMPLIFIED)">[ZHCN] CHINESE (SIMPLIFIED)</option>
                        <option value="CHINESE (TRADITIONAL)">[ZH_HANT] CHINESE (TRADITIONAL)</option>
                      </b-form-select>
                    </template>
                    <template v-else>
                      {{ user.languageCode || $t('COMMON.NOT_REGISTERED') }}
                    </template>
                  </p> 
                  <div v-if="editMode" class="text-center mt-4">
                      <j-button size="sm" variant="primary" buttonType="submit">{{$t('COMMON.SAVE')}}</j-button>
                      <j-button size="sm" class="ml-2" variant="light" buttonType="button" @click="editMode = false; loadUser()">{{$t('COMMON.CANCEL')}}</j-button>
                  </div>
                </div>
                <div class="col">
                  <label class="font-weight-bold">{{$t('USERS.SET_PASSWORD')}}</label>
                  <!-- Update label
                  Copy from commit: "DEV-2023-5345 : change password rule" in develop branch -->
                  <p v-if="user.daysUntilPasswordChange !== '' && user.daysUntilPasswordChange !== null && user.daysUntilPasswordChange !== undefined">
                    {{$t('PROFILE.PASSWORD_AGE_MESSAGE_1')}}{{ user.daysUntilPasswordChange }}{{$t('PROFILE.PASSWORD_AGE_MESSAGE_2')}}
                  </p>
                  <p>
                    <button class="btn btn-primary btn-sm" type="button" @click="$bvModal.show('password-change-modal');">{{$t('USERS.SET_PASSWORD')}}</button>
                  </p>
                  <!-- <div class="mt-4 border-top pt-4">
                    <label class="font-weight-bold">ユーザーデバイス履歴</label>
                    <div class="clearfix mt-2" v-if="devices.length <= 0">
                      <p>ユーザーデバイス未登録されていません。</p>  
                    </div> 
                    <div class="clearfix mt-2" v-for="device in devices" v-bind:key="device.id">
                      <div class="float-left" style="padding-top: 8px; padding-right:8px;">
                        <svg height="32" viewBox="0 0 24 24" version="1.1" width="32" aria-hidden="true"><path fill-rule="evenodd" d="M8.954 17H2.75A1.75 1.75 0 011 15.25V3.75C1 2.784 1.784 2 2.75 2h18.5c.966 0 1.75.784 1.75 1.75v11.5A1.75 1.75 0 0121.25 17h-6.204c.171 1.375.805 2.652 1.769 3.757A.75.75 0 0116.25 22h-8.5a.75.75 0 01-.565-1.243c.964-1.105 1.598-2.382 1.769-3.757zM21.5 3.75v11.5a.25.25 0 01-.25.25H2.75a.25.25 0 01-.25-.25V3.75a.25.25 0 01.25-.25h18.5a.25.25 0 01.25.25zM13.537 17c.125 1.266.564 2.445 1.223 3.5H9.24c.659-1.055 1.097-2.234 1.223-3.5h3.074z"></path></svg>
                      </div>
                      <div class="float-left">
                        <b>{{device.location || "Unknown"}} {{device.ip||'0.0.0.0'}}</b>
                        <p>最終アクセス日時: {{device.accessedAt || '≪なし≫'}}</p>
                        <div v-if="!device.approved">
                          <a class="text-success" href="#">承認</a>
                          <a class="text-secondary ml-4" href="#">ブロック</a>  
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <!--
                  <label class="font-weight-bold">メール通知設定</label>
                  <p>自動通知の受信可否をユーザー単位で設定することができます。</p>
                  <p>
                    <button class="btn btn-primary btn-sm" type="button" @click="$router.push(to='/supplier/manage/mail-config')">{{$t('EMAIL_NOTIFICATION.TITLE')}}</button>
                  </p>
                  -->
                  <!--
                  <div v-if="hasRole('S999')">
                    <label class="font-weight-bold">{{$t('EMAIL_NOTIFICATION.TITLE')}}</label>
                    <p>{{$t('EMAIL_NOTIFICATION.DESCRIPTION')}}</p>
                    <p>
                      <button class="btn btn-primary btn-sm" type="button" @click="$router.push(to='/supplier/manage/mail-config')">{{$t('EMAIL_NOTIFICATION.TITLE')}}</button>
                    </p> 
                  </div>     
                  -->
                  <div v-if="userOrgSwitch.length > 1">        
                    <label class="font-weight-bold">{{$t('HEADER.HEADER_SWITCH')}}</label>
                    <p>{{$t('PROFILE.DUTY_SETTING_DESCRIPTION')}}</p>
                    <p>
                      <button class="btn btn-primary btn-sm" type="button" @click="$bvModal.show('password-change-test1');">{{$t('PROFILE.SETTING_DUTY')}}</button>
                      <button class="btn btn-primary btn-sm ml-4" type="button" @click="$bvModal.show('password-change-test2');">{{$t('PROFILE.SWITCHING_DUTY')}}</button>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
      <PasswordChange v-if="user" modal-id="password-change-modal" :user="user" :is-self="true"></PasswordChange>
      <SettingDuty v-if="user" modal-id="password-change-test1" :user="user"></SettingDuty>
      <SwitchingDuty v-if="user" modal-id="password-change-test2" :user="user"></SwitchingDuty>
    </div>
  </div>
</template>

<script>
import UserInfoModel from '@/model/UserInfo.js';
import CompanyInfoModel from '@/model/CompanyInfo.js';
import PasswordChange from '@/components/user/PasswordChange.vue';
import SettingDuty from '@/components/user/SettingDuty.vue';
import SwitchingDuty from '@/components/user/SwitchingDuty.vue';

export default {
  components: { PasswordChange, SettingDuty, SwitchingDuty },
  data: function(){
    return {
      companyName: '',
      user: {
        roles: []
      },
      devices: [],
      editMode: false,
      userOrgSwitch: this.$store.state.user.userOrgSwitch,
    }
  },
  created: function(){
    this.loadUser();
  },
  methods: {
    onSave(){
      UserInfoModel.checkUser(this.$store.state.user.companyCode, this.user.email, this.user.code).then(result => {
        if(result.data.message == "true"){
          this.$warningMessage(this.$t('COMMON.USER_DUPLICATE_CHECK'));
          return Promise.resolve(false);
        }
        return UserInfoModel.saveSelf(this.$store.state.user.companyCode, this.user);
      }).then((result) => {
        if(result){
          this.$successMessage("担当者情報の更新が完了しました。");
        }
      }).catch(reason => this.$errorMessage(undefined, {reason}));
    },
    loadUser(){
      Promise.all([
        UserInfoModel.getSelf(this.$store.state.user.companyCode, this.$store.state.user.code),
        CompanyInfoModel.get(this.$store.state.user.companyCode)
      ]).then(([user, company]) => {
        this.user = user.data;
        this.companyName = company.data.fullName;
        this.user.languageCode = this.displayLanguageName();
      }).catch(reason => this.$errorMessage(undefined, {reason}));
    },
    displayLanguageName(){
      if(this.user.languageCode === null)return this.$t('COMMON.NOT_REGISTERED');
      else if(this.user.languageCode === 'ja') return "JAPANESE";
      else if(this.user.languageCode === 'en') return "ENGLISH";
      else if(this.user.languageCode === 'zhCN') return "CHINESE (SIMPLIFIED)";
      else if(this.user.languageCode === 'zh_hant') return "CHINESE (TRADITIONAL)";
      else return this.user.languageCode;
    },
    hasRole(roleCode){
      if(!this.$store.state.logged) return false;
      for(var i=0; i < this.$store.state.user.roles.length; i++){
        if(this.$store.state.user.roles[i].roleCode === roleCode){
          return true;
        }
      }
      return false;
    }
  }
}
</script>


<style>
  .override-font{
    font-family: none;
  }
</style>
