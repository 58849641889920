<template>
  <b-modal :id="modalId" @shown="load" size="lg" hide-footer>
    <template v-slot:modal-title>
      アカウント切替
    </template>
    <div>
      <p>ログインしているアカウントの切替</p>
    </div>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form @submit.prevent="handleSubmit(accept)">
        <div class="p-3 password-bg">
          <form class="ml-2 mr-2">
            <label v-html="$t('PROFILE.SWITCHING_DUTY_DESCRIPTION')" class="mb-1 mr-2" for="inlineFormCustomSelectPref">{{ }}</label>
            <select class="custom-select my-0 mt-2" id="inlineFormCustomSelectPref" v-model="userOrgMain">
              <option v-for="(orgSwitch, index) in userOrgSwitch" :key="orgSwitch.code" :value="orgSwitch.code">
                ({{ index == 0 ? $t("HEADER.HEADER_OPT_DUTY") : $t("HEADER.HEADER_OPT_ADDITIONAL") }}) [{{ orgSwitch.organizationCode }}] {{ orgSwitch.organizationName }} ({{ orgSwitch.companyFullName }})
              </option>
            </select>
          </form>
        </div>
        <div class="controls text-center mt-2">
          <j-button class="mt-3" variant="primary" buttonType="submit">
            切替
          </j-button>
          <j-button class="mt-3 ml-4" variant="light" buttonType="button" @click="reject()">
            <font-awesome-icon :icon="['fas','times']"></font-awesome-icon>
            キャンセル
          </j-button>
        </div>
      </form>
    </ValidationObserver>
    
  </b-modal>
</template>

<script>
import LoginModel from '@/model/Login.js';
import { setInteractionMode } from 'vee-validate'

export default {
  props: ['modalId','user', 'isSelf'],
  data: function(){
    return {
      current: "",
      currentVisible: false,
      userOrgSwitch: this.$store.state.user.userOrgSwitch,
      userOrgMain: this.$store.state.user.code
    }
  },
  created: function(){
    setInteractionMode('passive');
  },
  watch: {
  },
  methods:{
    accept(){
      if(this.$store.state.user.code === this.userOrgMain) {
        this.$bvModal.hide(this.modalId);
        return;
      }
      var newUser = this.userOrgSwitch.find(u => u.code === this.userOrgMain)
      LoginModel.switchUserOrg(newUser).then(result => {
        this.$store.commit("login", {
          accessToken: result.headers.authorization,
          user: result.data,
        });
        this.$router.push({ name: "MainMenu" });
        this.$router.go(0);
      }).catch(reason => console.error(reason));
    },
    reject(){
      this.$bvModal.hide(this.modalId);
    },
    load(){
      this.userOrgSwitch = this.$store.state.user.userOrgSwitch
    }
  }
}
</script>

<style lang="scss" scoped>
  .password-bg{
    background: $biz-side-bar-color;
  }
  .btn-light-link{
    border:none;
  }
</style>